import Login from '@features/auth/components/Login';
import GuardedRoute from '@features/layout/components/GuardedRoute';
import React from 'react';
import Head from 'next/head';
import { useIntl } from 'react-intl';
import { GetServerSideProps } from 'next';
import { IGetServerSideProps } from 'model/global.model';

const LoginPage = ({ title }: IGetServerSideProps) => {
	const { formatMessage } = useIntl();

	return (
		<>
			<Head>
				<title>{formatMessage({ id: 'bcs-Title-Login' })}</title>
				<link rel='icon' href='/favicon.ico' />
				<meta
					name='viewport'
					content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
				/>
				<meta name='theme-color' content='#ffffff' />
				<meta property='og:title' content={title} key='ogTitle' />
			</Head>
			<GuardedRoute redirectTo={'/'} isAuthenticationRoute>
				<Login />
			</GuardedRoute>
		</>
	);
};

export const getServerSideProps: GetServerSideProps = async () => {
	return {
		props: {
			title: 'Вход | Gamy.fun',
		},
	};
};

export default LoginPage;

import { getCurrentUser } from '@features/auth/store/auth.selectors';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

interface IGuardedRoute {
	redirectTo: string;
	children: ReactNode;
	isAuthenticationRoute?: boolean;
	redirectCallback?: () => void;
}

const GuardedRoute = ({ redirectTo, isAuthenticationRoute, redirectCallback, children }: IGuardedRoute) => {
	const currentUser = useSelector(getCurrentUser);

	const router = useRouter();

	const shouldAllowAccess = (!!currentUser && !isAuthenticationRoute) || (!currentUser && isAuthenticationRoute);

	if (!shouldAllowAccess) {
		if (redirectCallback) {
			redirectCallback();
		}

		router.replace(redirectTo);
		return null;
	}

	return <>{children}</>;
};

export default GuardedRoute;
